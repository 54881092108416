<!--
 * @Author: DY
 * @Date: 2020-08-31 20:25:29
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-10-20 15:25:16
 * @Describe:
-->
<template>
    <div class="miniMap">
        <el-amap
            vid="amap_min"
            :zoom="zoom_min"
            :center="mapCenter_min"
            :amap-manager="amapManager"
            :events="mapEvents_min"
        >
            <el-amap-marker
                vid="component-marker"
                :icon="marker.icon"
                :position="marker.position"
            ></el-amap-marker>
            <el-amap-info-window
                :auto-move="true"
                :position="Info.position"
                :content="Info.content"
                :offset="Info.offset"
                :visible="Info.visible"
                :events="Info.events"
            >
            </el-amap-info-window>
        </el-amap>
    </div>
</template>
<script>

import VueAMap from 'vue-amap';
const amapManager = new VueAMap.AMapManager();
VueAMap.initAMapApiLoader({
    key: 'b4007ecc143884e79eab2c515ede82cf',
    plugin: [
        'AMap.Autocomplete',
        'AMap.PlaceSearch',
        'AMap.ToolBar',
        'AMap.Geolocation',
        'AMap.Geocoder',
        'AMap.AMapManager',
        'AMap.Marker',
        'AMap.GeometryUtil',
    ],
    v: '1.4.4',
});

export default {
    // 小地图
    name: 'mini-map',
    components: {},
    props: {
        essentialInfo: {
            type: [Array, Object, String],
        },
        mapKey: {
            type: [Array, Object, String],
        },
    },
    data() {
        return {
            zoom_min: 10,
            // 地图中心点坐标值
            mapCenter_min: [117.120098, 36.6512],
            // 地图管理对象
            amapManager,
            mapEvents_min: {
                init(o) {
                    o.setMapStyle('amap://styles/darkblue');
                },
            },
            marker: {
                icon: require('./images/icon.png'),
                position: [117.120098, 36.6512],
            },
            Info: {
                position: [117.120098, 36.6512],
                visible: false,
                content: '',
                offset: [0, -30],
                events: {},
            },
        };
    },
    created() {
    },
    mounted() {},
    activated() {},
    update() {},
    beforeRouteUpdate() {},
    methods: {
    },
    filter: {},
    computed: {},
    watch: {
        essentialInfo(newVal) {
            if (newVal) {
                if (newVal.x_coordinate_point && newVal.y_coordinate_point) {
                    this.mapCenter_min = [newVal.x_coordinate_point, newVal.y_coordinate_point];
                    this.marker.position = [newVal.x_coordinate_point, newVal.y_coordinate_point];
                    this.Info.position = [newVal.x_coordinate_point, newVal.y_coordinate_point];
                    this.Info.content = newVal.engineering_location;
                    this.Info.visible = true;
                }
            }
        },
    },
};
</script>

<style lang="stylus">
.miniMap
    height 100%
    .amap-icon
        img
            width 0.18rem
            height 0.21rem
    .amap-logo
        display none
        opacity 0 !important
    .amap-copyright
        opacity 0
    .amap-info
        .amap-info-contentContainer
            .amap-info-content
                padding 0.1rem
                max-width 1.9rem
                height auto
                border-radius 2px
                font-size 0.12rem
                line-height 0.2rem
                color rgba(255,255,255,0.5)
                background rgba(0, 22, 54, 0.8)
                box-shadow inset 1px 1px 20px 0px rgba(2, 174, 252, 0.8)
            .amap-info-sharp
                border-top-color rgba(2, 174, 252, 0.4)
            .amap-info-close
                top 0.1rem
                right 0.1rem !important
                display none
            .engineering_Info
                width 100%
                height 100%
</style>
